<template>
    <div class="teamwork-wrap">
        <div class="teamwork-content">
            <div class="teamwork-list">
                <div class="teamwork-left">
                    <div class="teamwork-left-header">
                        <img :src="logo" />
                        沃创云
                    </div>
                    <div class="introduce">全新智能化、移动化、数字化平台</div>
                    <div class="msg">改善你的业务、满足你全部需求助力企业销售业绩全面提升</div>
                </div>
                <div class="teamwork-right">
                    <div class="teamwork-right-header">提交申请体验</div>
                    <el-form label-position="right"  :model="formTeamwork" :rules="rules" ref="teamworkData">
                        <el-form-item prop="company_name">
                            <el-input v-model="formTeamwork.company_name" placeholder="请填写您的公司名称(完整)"></el-input>
                        </el-form-item>
                        <el-form-item prop="industry">
                            <el-input v-model.trim="formTeamwork.industry"  placeholder="请填写您的行业"></el-input>
                        </el-form-item>
                        <el-form-item prop="name">
                            <el-input v-model.trim="formTeamwork.name"  placeholder="请填写您的姓名"></el-input>
                        </el-form-item>
                        <el-form-item prop="mobile">
                            <el-input v-model.trim="formTeamwork.mobile"  placeholder="请填写您的联系方式"></el-input>
                        </el-form-item>
                        <el-form-item prop="position">
                            <el-input v-model.trim="formTeamwork.position"  placeholder="请填写您的职位"></el-input>
                        </el-form-item>
                        <el-form-item prop="seats_num">
                            <el-input v-model.trim="formTeamwork.seats_num"  placeholder="请填写需求数量(坐席数量)"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" :disabled="isClick" :loading="isLoading" @click="submitForm">立即提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { validaInteger, validaZH, validaPhone, validaMobile } from '@/utils/valida'
    import { systemType } from '@/utils/enum'
    import { setStorage, mdSignKey } from '@/utils/common'
    const changeCompanyName = (rule, value, callback) => {
        if (!validaZH(value)) {
          callback(new Error(''));
        } else {
          callback();
        }
    };
    const changeSeatsNum = (rule, value, callback) => {
        if (!validaInteger(value)) {
          callback(new Error('需求数量(坐席数量)有误'));
        } else {
          callback();
        }
    };
    export default {
        data() {
            return {
                logo: require('@/assets/image/logo.png'),
                formTeamwork: {
                    company_name: '',
                    industry: '',
                    name: '',
                    mobile: '',
                    position: '',
                    seats_num: '',
                    invite_code: ''
                },
                rules: {
                    company_name: [
                        { validator: this.validatCompnayName, trigger: 'blur' }
                    ],
                    industry: [
                        { validator: changeCompanyName, trigger: 'blur', message: '请填写您的行业' }
                    ],
                    name: [
                        { validator: changeCompanyName, trigger: 'blur', message: '请填写您的姓名' }
                    ],
                    mobile: [
                        { validator: this.changeMobile, trigger: 'blur' }
                    ],
                    position: [
                        { validator: changeCompanyName, trigger: 'blur', message: '请填写您的职位' }
                    ],
                    seats_num: [
                        { validator: changeSeatsNum, trigger: 'blur' }
                    ]
                },
                isLoading: false,
                isClick: false,
                copyName: '',
                copyMobile: '',
                isGetCompnayName: false,
                isGetCompnayMobile: false,
                isVerifyName: false
            }
        },
        async created() {
            const { userId, system_type, token } = this.$route.query
            // token优选
            if(token) {
                if(validaMobile()) {
                    this.$router.replace({ path: '/mobileApplyExperience', query: { token } })
                    return
                }
                setStorage('token', token)
                const result = await this.$store.dispatch('getUserInfo')
                if(!result) {
                    this.isClick = true
                }
                return
            }

            if(!userId) {
                this.$errorPrompt('用户id获取失败')
                this.isClick = true
                return
            }
            if(!systemType.includes(system_type)){
                this.$errorPrompt('system_type类型错误')
                this.isClick = true
                return
            }
            if(validaMobile()) {
                this.$router.replace({ path: '/mobileApplyExperience', query: { userId, system_type } })
                return
            }
            const result = await this.$store.dispatch('getToken', { userId, isDetails: true, system_type })
            if(!result){
                this.isClick = true
            }

           
            
        },
        methods: {
            async validatCompnayName(rule, value, callback) {
                const val = value.replace(/\s+/g,'')
                this.formTeamwork.company_name = val
                if(val && this.copyName === val) {
                    if(this.isGetCompnayName) {
                        callback(new Error('您输入的企业有误，请核实后重新输入'));
                        return
                    }
                    if(this.isVerifyName) {
                        callback(new Error('您填写的企业已存在'));
                        return
                    }
                    return
                }
                const { code, data } = await this.$postHttp(this.$api.queryCompany, {
                    company_name: val
                })
                this.copyName = val
                if(code == 1 && data.code == 1) {
                    this.isGetCompnayName = false
                    const result = await this.verifyCompanyInfo('company_name')
                    if(result.code == 0) {
                        this.isVerifyName = true
                        callback(new Error('您填写的企业已存在'));
                    } else {
                        this.isVerifyName = false
                        callback();
                    }
                } else {
                    this.isGetCompnayName = true
                    callback(new Error('您输入的企业有误，请核实后重新输入'));
                }
            },
            async changeMobile (rule, value, callback){
                if (!validaPhone(value)) {
                    callback(new Error('您填写的手机号码有误'));
                } else {
                    if(this.copyMobile === value) {
                        if(this.isGetCompnayMobile) {
                            callback(new Error('您填写的手机号已存在'));
                        }
                        return
                    }
                    const result = await this.verifyCompanyInfo('mobile')
                    if(result.code == 0) {
                        this.isGetCompnayMobile = true
                        callback(new Error('您填写的手机号已存在'));
                    } else {
                        this.isGetCompnayMobile = false
                        callback();
                    }
                }
            },
            verifyCompanyInfo(valKey) {
                const params = {
                    [valKey]: this.formTeamwork[valKey],
                    sign: mdSignKey(this.$store.state.sign_key, this.formTeamwork[valKey], valKey)
                }
                return this.$postHttp(this.$api.verifyCompany,params)
            },
            submitForm() {
                this.formTeamwork.mobile && (this.copyMobile = this.formTeamwork.mobile)
                this.$refs.teamworkData.validate((valid) => {
                    if (valid) {
                        this.isLoading = true
                        this.formTeamwork.invite_code = this.$store.state.userInfo.invite_code
                        this.$postHttp(this.$api.postApplyEnjoy, this.formTeamwork).then(res => {
                            if(res.code == 1) {
                                this.$successPrompt('提交成功');
                                this.$refs.teamworkData.resetFields()
                            }
                            this.isLoading = false
                            this.copyMobile = ''
                        }).catch(() => {
                            this.copyMobile = ''
                        })
                    } 
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.teamwork-wrap{
    min-width: 1200px;
    height: 100%;
    background: url('../assets/image/teamwork_bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .teamwork-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include wh(900px,578px);
        box-shadow: 0px 10px 20px rgba(7, 0, 45, 0.2);
        .teamwork-list{
            @include flex();
            overflow: hidden;
             @include wh(100%,100%);
            .teamwork-left{
                @include wh(50%,100%);
                background: url('../assets/image/teamwork_left.png') no-repeat;
                background-size: 100% 100%;
                padding: 50px 69px 50px 50px;
                .teamwork-left-header{
                    @include flex(center,flex-start);
                    @include font(36px,#FFFFFF);
                    img{
                        @include wh(53px,35px);
                        margin-right: 8px;
                    }
                }
                .introduce{
                    margin-top: 27px;
                    margin-bottom: 20px;
                    @include font(20px,#fff);
                    opacity: 0.8;
                }
                .msg{
                    @include font(14px,#fff);
                    opacity: 0.6;
                }
            }
            .teamwork-right{
                @include wh(50%,100%);
                background: #fff;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                overflow: hidden;
                padding: 32px 35px;
                .teamwork-right-header{
                    @include font(24px,$main_font_color);
                    font-weight: bold;
                    margin-bottom: 32px;
                }
                ::v-deep .el-form-item{
                    margin-bottom: 20px;
                }
                ::v-deep .el-input__inner{
                    height: 46px;
                    border: none;
                    background: #F5F7FD;
                    border-radius: 4px;
                    @include font(14px,$main_font_color);
                    line-height: 46px;
                    padding-left: 16px;
                }
                ::v-deep .el-button{
                    @include wh(100%,50px);
                    line-height: 50px;
                    @include font(16px,#fff,center);
                    border-radius: 45px;
                    margin-top: 10px;
                    padding: 0;
                    &.el-button--primary{
                        background: $main_color;
                        border-color: $main_color;
                    }
                    &.is-click{
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
</style>

